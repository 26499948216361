<script setup lang="ts">

const props = defineProps<{
  dotted?: boolean
  color?: string
  forcePrint?: boolean
}>()

const classes = computed(() => {
  return [
    'border-1',
    'border-secondary-border',
    'rounded-2xl',
    props.forcePrint ? 'print:border-opacity-100' : 'print:border-opacity-0',
    props.dotted ? 'border-dotted' : 'border-solid',
    props.color ? `bg-${props.color}` : 'bg-primary-background'
  ]
})
</script>

<template>
  <div :class="classes">
    <slot />
  </div>
</template>
